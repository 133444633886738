@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  color: white;
}

.App {
  text-align: center;
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  min-width: 100vw;
  max-width: 100vw;
  background-color: #121212;
  overflow: none;
  display: grid;
  grid-template-columns: repeat(15, 1fr);
  grid-template-rows: repeat(8, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  overflow: hidden;
}

#loginFormContainer {
  background-color: #232323;
  border-radius: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 30%;
  min-height: 30%;
}
#loginFormContainer #loginFormHeader {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
#loginFormContainer #loginFormHeader #loginFormHeaderText {
  grid-area: 1/2/2/5;
  font-size: 2rem;
  padding: 10px;
  margin-top: 0px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}
#loginFormContainer #loginForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 10px;
}

#homePageContainer {
  background-color: #232323;
  border-radius: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 90%;
  min-height: 90%;
  max-height: 90%;
  max-width: 90%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
#homePageContainer #homePageSearchContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
#homePageContainer #homePageSearchContainer #homePageSearchField {
  margin: 20px;
}
#homePageContainer #homePageSearchResultsContainer {
  width: 100%;
}

#notFoundContainer {
  background-color: #232323;
  border-radius: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 40%;
  min-height: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
#notFoundContainer h1 {
  font-size: 10rem;
  padding: 10px;
  margin-top: 0px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}
#notFoundContainer h2 {
  font-size: 2rem;
  padding: 10px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}
#notFoundContainer h3 {
  padding: 10px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}